import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import React, { useRef, useState } from "react";
import { FileUploadHistoryDto } from "../common/upload.typings";
import { Badge, Button } from "@sgbs-ui/core";
import { capitalize } from "lodash";
import { DateTime } from "luxon";
import { downloadUploadFile, downloadUnknownClientsFile } from "../../../queries/upload";
import { format } from "date-fns";
import { ValueFormatterParams } from "ag-grid-community";
import { formatAmount } from "../../utils/numbers/numberUtils";

export interface Props {
  uploadHistory: FileUploadHistoryDto[];
}

export const UploadGrid: React.FC<Props> = ({ uploadHistory }) => {
  const [isShowingAll, setIsShowingAll] = useState<boolean>(false);
  const DEFAULT_PAGINATION_SIZE = 15;
  const gridRef = useRef<AgGridReact>(null);

  const onExtractUnknownClients = (item: FileUploadHistoryDto) => { 
    downloadUnknownClientsFile(item.id);
  };

  const onDownload = (item: FileUploadHistoryDto) => {
    downloadUploadFile(item.id);
  };

  const handleShowAllClick = () => {
    setIsShowingAll(!isShowingAll);
  }

  React.useEffect(() => {
    if (isShowingAll) {
      const rowCount = gridRef.current?.api.paginationGetRowCount();
      gridRef.current?.api?.paginationSetPageSize(rowCount);
    } else {
      gridRef.current?.api?.paginationSetPageSize(DEFAULT_PAGINATION_SIZE);
    }
  }, [isShowingAll])

  return (
    <div className="ag-theme-alpine">
      <AgGridReact
        ref={gridRef}
        defaultColDef={{
          sortable: true,
          filter: true,
          resizable: true,
        }}
        domLayout="autoHeight"
        rowData={uploadHistory}
        pagination={true}
        paginationPageSize={DEFAULT_PAGINATION_SIZE}
        onGridReady={() => {}}
        suppressHorizontalScroll={true}
        enableCellExpressions={true}
        tooltipShowDelay={1000}
      >
        <AgGridColumn field="fileName" headerName="File Name" flex={1} />
        <AgGridColumn field="supplierMnemo" headerName="Supplier Name" flex={1} />
        <AgGridColumn field="uploadedDate" headerName="Uploaded Date" flex={1} valueFormatter={(date: ValueFormatterParams) => format(new Date(date.value), "yyyy-MM-dd HH:mm:ss")} />
        <AgGridColumn 
          field="accountingPeriod" 
          headerName="Accounting Period" 
          flex={1} 
          cellRendererFramework={(params: any) => {
            const accountingPeriod = DateTime.fromFormat(params.value, "yyyyMM");
            return (
              <>
                { accountingPeriod.toFormat('MM/yyyy') }
              </>
            );
          }}
        />
        <AgGridColumn
          field="checkStatus"
          headerName="Status"
          flex={1}
          cellRendererFramework={(params: any) => {
            switch(params.value) {
              case "success":
                return <Badge color="success" text={"Success"} />
              case "rejected":
                return <Badge color="danger" text={"Rejected"} />
              case "canceled":
                return <Badge color="warning" text={"Canceled"} />
              case "previousUpload":
                return <Badge color="secondary" text={"Previous Upload"} />
              default:
                return capitalize(params.value);
            }
          }}
        />
        <AgGridColumn field="userName" headerName="User Name" flex={1} />
        <AgGridColumn 
          field="totalNbi"
          headerName="Total NBI"
          flex={1}
          valueFormatter={(nbi: ValueFormatterParams) => formatAmount(nbi.value as number)}
        />
        <AgGridColumn 
          field="totalNbiUnknown"
          headerName="NBI on unknown clients"
          flex={1}
          valueFormatter={(unknownClientNbi: ValueFormatterParams) => formatAmount(unknownClientNbi.value as number)}
        />
        <AgGridColumn 
          field="extractUnknownClient"
          headerName="Extract unknown clients"
          flex={1}
          cellRendererFramework={(node: { data: FileUploadHistoryDto }) => (
            <>
              { node.data.hasUnknownClient
              ? <Button
                className="btn btn-sm btn-primary btn-icon-text"
                onClick={(e) => onExtractUnknownClients(node.data)}
                icon="download">
                Extract clients
              </Button> 
              : <></>}
            </>
          )}
        />
        <AgGridColumn 
          field="downloadFile"
          headerName="Download file"
          flex={1}
          cellRendererFramework={(node: { data: FileUploadHistoryDto }) => (
            <Button
              className="btn btn-sm btn-primary btn-icon-text"
              onClick={(e) => onDownload(node.data)}
              icon="download"
            >
              Download
            </Button>
          )}
        />
      </AgGridReact>
      <div className="text-center mt-3 mb-3">      
        <Button
          className="btn-lg btn-primary"
          text={isShowingAll ? "Hide rows" : "Show all rows"}
          onClick={handleShowAllClick}
        />
      </div>
    </div>
  );
}